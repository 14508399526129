<template>
    <div class="page">
        <div class="box">
            <img src="../assets/img/error.png" alt="">
            <h2>{{msg}}</h2>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                msg: '系统维护中...'
            }
        },
        created() {
            if (this.headerList.length > 1) this.$router.replace('/');
        }
    }
</script>
<style scoped>
    .box {
        width: 80%;
        margin: 6% auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .box img {
        max-width: 350px;
        margin-bottom: 15px;
    }
    
    .box h2 {
        font-size: 24px;
    }
</style>